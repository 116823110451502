import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FullComponent } from './layouts/full/full.component';
import {RouteGuardService} from './services/route-guard.service';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'cafe',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/cafe/dashboard',  pathMatch: 'full' },
      {
        path: '',
        loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule),
        canActivate: [ RouteGuardService],
        data: { expectedRole: ['admin', 'user','x1'] }
      },
      {
        path: 'dashboard', canActivate: [ RouteGuardService],
        data: { expectedRole: ['admin', 'user', 'roleTest'] },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
