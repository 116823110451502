<div class="bg-image"></div>
<app-best-seller></app-best-seller>

<div class="wrapper sticky">
  <nav class="navbar-fixed-top">
    <a href="#" class="logo">
      <mat-icon>storefront</mat-icon> Cafe Management System
    </a>
    <ul>
      <li>
        <a href="https://cafe.renatobonfim.com/api/swagger-ui/index.html">Check the API</a>
        <a (click)="handleLoginAction()">Sign in</a>
        <a (click)="handleSignupAction()">Sign up <mat-icon>app_registration</mat-icon></a>
        <a (click)="handleForgotAction()">Forgot Password</a>
      </li>
    </ul>
  </nav>
</div>
<div class="footer" id="signup">
  <h2>All right reserved</h2>
</div>
