<mat-toolbar color="primary">
  <mat-toolbar-row fxLayout="row">
    <span>Confirmation</span>
    <span class="spacer"></span>
    <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
  <h6>Are you sure you want to {{details.messageX}}</h6>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onEmitStatusChange.emit();">
    <mat-icon>check_circle</mat-icon>
    Yes
  </button>
  <button mat-raised-button mat-dialog-close color="warn">
    <mat-icon>cancel</mat-icon>
    NO
  </button>
</mat-dialog-actions>
