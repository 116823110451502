<mat-toolbar color="primary">
  <mat-toolbar-row fxLayout="row">
    <span>Login</span>
    <span class="spacer"></span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="loginForm">
    <div fxFlex fxLayout="column">

      <div style="border: 1px solid red">
        <p>To explore, use the provided credentials or register by clicking 'Sign up'.</p>
        <p>bob320903@mailinator.com pass1234 </p>
        <p>pietro9999@mailinator.com pass1234 </p>
        <p>
          <a href="https://cafe.renatobonfim.com/api/swagger-ui/index.html"
             style="text-align: center; color:red; background-color: ">API available here:
            https://cafe.renatobonfim.com/api/swagger-ui/index.html</a>
        </p>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="loginForm.controls?.email?.touched && loginForm.controls?.email?.invalid">
          <span *ngIf="loginForm?.controls?.email?.errors?.required">Email is required</span>
          <span *ngIf="loginForm?.controls?.email?.errors?.pattern">Email is invalid</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" required [type]="hidePassword? 'password':'text'">
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword;">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.controls?.password?.touched && loginForm.controls?.password?.invalid">
          <span *ngIf="loginForm?.controls?.password?.errors?.required">Password is required</span>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary" [disabled]="loginForm?.invalid" (click)="handleSubmit()">
    <mat-icon>login</mat-icon>
    Login
  </button>
  <button mat-raised-button mat-dialog-close color="warn">
    <mat-icon>cancel</mat-icon>
    Close
  </button>
</mat-dialog-actions>
