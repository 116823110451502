<mat-toolbar color="primary">
  <mat-toolbar-row fxLayout="row">
    <span>Change Password</span>
    <span class="spacer"></span>
    <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="this.changePasswordForm">
    <div fxFlex fxLayout="column">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Old Password</mat-label>
        <input matInput formControlName="oldPassword" required [type]="hideOldPassword?'password':'text'">
        <button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword">
          <mat-icon>{{ hideOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.controls.oldPassword.touched && changePasswordForm.controls.oldPassword.invalid">
          <span *ngIf="changePasswordForm.controls.oldPassword?.errors?.required">This field is required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>New Password</mat-label>
        <input matInput formControlName="newPassword" required [type]="hideNewPassword?'password':'text'">
        <button mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword">
          <mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.controls.newPassword.touched && changePasswordForm.controls.newPassword.invalid">
          <span *ngIf="changePasswordForm.controls.newPassword?.errors?.required">This field is required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>New Password Confirmation</mat-label>
        <input matInput formControlName="confirmPassword" required [type]="hideConfirmPassword?'password':'text'">
        <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
          <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.controls.confirmPassword.touched && changePasswordForm.controls.confirmPassword.invalid">
          <span *ngIf="changePasswordForm.controls.confirmPassword?.errors?.required">This field is required</span>
        </mat-error>
        <mat-hint *ngIf="invalidFields() && !( changePasswordForm?.controls?.confirmPassword?.invalid)">
          <span>New Password and confirm Password dont match !</span>
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="handleSubmit()" [disabled]="invalidFields() || !(changePasswordForm.valid && changePasswordForm.dirty)" >
    <mat-icon>save</mat-icon>
    Update
  </button>
  <button mat-raised-button mat-dialog-close color="accent" >
    <mat-icon>
      cancel
    </mat-icon>
    Close
  </button>
</mat-dialog-actions>
