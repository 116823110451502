<mat-toolbar color="primary">
  <mat-toolbar-row fxLayout="row">
    <span>Forgot Password</span>
    <span class="spacer"></span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="forgotPasswordForm">
    <div fxFlex fxLayout="column">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="forgotPasswordForm?.controls?.email?.touched && forgotPasswordForm?.controls?.email?.invalid">
          <span *ngIf="forgotPasswordForm?.controls?.email?.errors?.required">Field is required</span>
          <span *ngIf="forgotPasswordForm?.controls?.email?.errors?.pattern">Filed is invalid</span>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary" [disabled]="forgotPasswordForm?.invalid"
          (click)="handleSubmit()">
    <mat-icon>send</mat-icon>
    Send Password
  </button>
  <button mat-raised-button mat-dialog-close color="warn">
    <mat-icon>cancel</mat-icon>
    Close
  </button>
</mat-dialog-actions>
